import React, {useState} from 'react';
import {Link} from 'react-scroll';
import styled from 'styled-components';
import {useI18next, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, colors, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import Container from 'components/Container';
import CurvedBorder from 'components/CurvedBorder';
import Loader from 'components/Loader';
import {AnchorLink} from 'components/AnchorLink';

import Countries from 'utils/countries';

import TopSectionImage from 'images/email-marketing-expert.svg';
import MoonMailExpert from 'images/moonmailexpert.svg';
import Marketer from 'images/marketer.svg';
import Designer from 'images/designer.svg';
import Aws from 'images/aws.svg';
import Location from 'images/location.svg';
import ExpertAws from 'images/email-marketing-expert-aws.svg';
import ExpertMarketing from 'images/email-marketing-expert-marketing.svg';
import ExpertDevelopment from 'images/email-marketing-expert-development.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5%;
    right: 5%;
    border-bottom: 1px solid #e5e5e5;
  }

  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSub = styled.p`
  font-size: 25px;
  margin: 10px 0 0;
`;
const IndexSectionHeadline = styled.div``;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 700px;
  margin: 20px auto 40px;
  font-weight: 300;
`;

const FeatureSection = styled.section`
  color: #fff;
  text-align: center;
  margin: 0;
  a {
    cursor: pointer;
  }
  @media ${media.mobile} {
    margin-bottom: 80px;
  }
`;

const FeatureContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  padding: 60px ${styles.containerPadding};
  @media ${media.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureFigure = styled.figure`
  color: #fff;
  text-align: center;
  max-width: 260px;
  margin: 0;
  img {
    margin-bottom: 0;
  }
`;

const FeatureCaption = styled.figcaption`
  p {
    font-size: 19px;
    line-height: 31px;
    font-weight: 300;
    margin: 10px 0 0 0;
  }
  h2 {
    font-size: 29px;
    font-weight: 400;
    margin: 2px 0 15px;
    line-height: 31px;
  }
  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
  @media ${media.tablet} {
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 22px;
    }
  }
  @media ${media.mobile} {
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 20px;
      margin: 0 0 10px;
    }
  }
`;

const BecomeExpertSection = styled.div`
  min-height: 0;
  ${IndexSectionContainer} {
    padding: 70px 4%;
    &:after {
      display: none;
    }
  }
`;

const ExpertFilterSection = styled.section``;

const ExpertFilterContainer = styled(Container)`
  text-align: center;
`;
const ExpertFiltersHeadline = styled.p`
  color: ${colors.textLight};
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const ExpertFilterLabel = styled.p`
  font-size: 21px;
  font-weight: 600;
`;

const TypeFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -2.5px;
  @media ${media.mobile} {
    flex-direction: column;
  }
`;

const TypeFilter = styled.label<{active?: boolean}>`
  text-align: center;
  border: 1px solid ${colors.border};
  border-radius: 7px;
  flex: 1;
  padding: 35px 10px;
  margin: 0 2.5px;
  font-size: 18px;
  transition: all 0.25s;
  cursor: pointer;
  @media ${media.mobile} {
    width: 100%;
    margin-bottom: 10px;
  }
  img {
    margin-bottom: 10px;
    filter: grayscale(100%);
    opacity: 0.6;
    transition: all 0.25s;
  }
  input[type='radio'] {
    margin-top: 15px;
  }
  ${props =>
    props.active &&
    `
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.06);
    img {
      filter: none;
      opacity: 1;
    }
  `}
`;

const CountryFilter = styled.div`
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  img {
    vertical-align: middle;
    margin: 0 10px 10px 0;
  }
  select {
    width: 200px;
    margin-left: 15px;
    @media ${media.mobile} {
      margin: 15px 0 0 0;
      width: 100%;
    }
  }
`;

const Experts = styled.section``;

const CurvedBorderBottom = styled(CurvedBorder)`
  transform: rotate(180deg);
  fill: rgb(37, 42, 59);
`;

const ShowMore = styled(Button)`
  margin: 30px 0 0;
  color: ${colors.text};
  font-weight: 600;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    border-bottom: 2px solid ${colors.action};
  }
  &:hover {
    text-decoration: none;
    color: ${colors.action};
  }
`;

const ExpertContainer = styled(Container)`
  max-width: 1024px;
`;

const Expert = styled.div`
  border: 1px solid ${colors.border};
  border-radius: 7px;
  padding: 30px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  &:first-child {
    margin-top: 0;
  }
  @media ${media.mobile} {
    flex-direction: column;
    .button {
      display: block;
      text-align: center;
      width: 100%;
    }
  }
`;

const ExpertLogo = styled.div`
  width: 135px;
  text-align: center;
  flex-shrink: 0;
  img {
    max-width: 100%;
  }
`;

const ExpertSummary = styled.div`
  flex: 1;
  margin: 0 60px;
  h4 {
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 10px;
  }
  p {
    margin: 0 0 10px;
  }
  @media ${media.mobile} {
    margin: 30px 0;
  }
`;

const ExpertListEmpty = styled.div`
  font-size: 21px;
  font-weight: 600;
  margin: 40px 0;
  text-align: center;
  height: 2.28571429rem;
`;

const ExpertLoc = styled.span`
  color: darkgrey;
`;

const getExpertLogoUrl = (userId: string, logo: string) => {
  const EXPERT_LOGOS_BUCKET = 'expert-logos';
  if (!logo) return Designer;
  return `https://${EXPERT_LOGOS_BUCKET}.s3.amazonaws.com/${userId}/${logo}`;
};

const MainPage: React.FC = () => {
  const {t} = useI18next();

  const [isMore, setIsMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [expertList, setExpertList] = useState();
  const [expertType, setExpertType] = useState('marketing');
  const [expertCountry, setExpertCountry] = useState('Spain');

  const fetchData = (type: string, country: string) => {
    setIsLoading(true);
    fetch(`https://api.moonmail.io/experts?type=${type}&country=${country}`)
      .then(res => (res.ok ? res.json() : setIsErrored(true)))
      .then(data => {
        setExpertList(data.items);
        setIsLoading(false);
      });
  };

  const onTypeChange = (type: string) => () => {
    setExpertType(type);
    fetchData(type, expertCountry);
  };

  const onCountryChange = (country: string) => {
    setExpertCountry(country);
    fetchData(expertType, country);
  };

  return (
    <>
      <Helmet title={t('Email Marketing Experts')}>
        <meta
          itemProp="description"
          content={t(
            'Find an email marketing expert with high expertise and knowledge of the MoonMail platform. Get the most out of your email marketing campaigns.'
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={TopSectionImage}
                alt={t('Email Marketing Experts')}
                title={t('Email Marketing Experts')}
                width={296}
                height={201}
              />
              <h1>{t('MoonMail Email Marketing Experts')}</h1>
              <IndexSectionSummary>
                <Trans>
                  Need help to migrate your subscribers or to improve your email templates? Browse
                  our top certified email marketing experts to get help from companies with
                  competency expertise, and knowledge of MoonMail and take your email marketing
                  campaigns to the moon.
                </Trans>
              </IndexSectionSummary>

              <Button
                as={Link}
                to="expert-filters"
                spy={true}
                smooth={true}
                offset={-70}
                duration={300}
                title={t('Find Email Marketing Experts')}>
                {t('Look for an Expert')}
              </Button>
            </IndexSectionContainer>
          </IndexSection>
          <FeatureSection>
            <FeatureContainer>
              <FeatureFigure>
                <img
                  src={Marketer}
                  alt={t('Email marketing consultant')}
                  title={t('Email marketing consultant')}
                  height="65"
                />
                <FeatureCaption>
                  <p>{t('Need to get')}</p>
                  <h2>{t('Your Message Across?')}</h2>
                  <Link
                    to="expert-filters"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                    title={t('Marketing Experts who can help')}>
                    {t('Our Marketing Experts Can Help')}
                  </Link>
                </FeatureCaption>
              </FeatureFigure>
              <FeatureFigure>
                <img
                  src={Designer}
                  alt={t('Email marketing design')}
                  title={t('Email marketing design')}
                  height="65"
                />
                <FeatureCaption>
                  <p>{t('Do you need to')}</p>
                  <h2>{t('Make that Logo Bigger?')}</h2>
                  <Link
                    to="expert-filters"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                    title={t('Best email design')}>
                    {t('Our Design & Development Experts Can Help')}
                  </Link>
                </FeatureCaption>
              </FeatureFigure>
              <FeatureFigure>
                <img
                  src={Aws}
                  alt={t('Email marketing for Amazon Web Services')}
                  title={t('Email marketing for Amazon Web Services')}
                  height="65"
                />
                <FeatureCaption>
                  <p>{t('Need MoonMail')}</p>
                  <h2>{t('Deployed to your AWS account?')}</h2>
                  <Link
                    to="expert-filters"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                    title={t('Email marketing experts on AWS')}>
                    {t('Our AWS Experts Can Help')}
                  </Link>
                </FeatureCaption>
              </FeatureFigure>
            </FeatureContainer>
          </FeatureSection>
          <CurvedBorder />
        </Stars>
        <ExpertFilterSection id="expert-filters">
          <ExpertFilterContainer>
            <ExpertFiltersHeadline>
              <Trans>
                Select the kind of expert you need and browse through MoonMail Experts around the
                world!
              </Trans>
            </ExpertFiltersHeadline>
            <ExpertFilterLabel>
              <Trans>I need a:</Trans>
            </ExpertFilterLabel>
            <TypeFilters>
              <TypeFilter active={expertType === 'marketing'}>
                <img
                  src={ExpertMarketing}
                  css={`
                    margin-left: 20px;
                  `}
                />
                <div>
                  <Trans>Marketing Expert</Trans>
                </div>
                <input
                  type="radio"
                  name="expert-type"
                  value="marketing"
                  checked={expertType === 'marketing'}
                  onChange={onTypeChange('marketing')}
                />
              </TypeFilter>
              <TypeFilter active={expertType === 'development'}>
                <img src={ExpertDevelopment} alt="email-marketing-expert-marketing" />
                <div>
                  <Trans>Design & Development Expert</Trans>
                </div>
                <input
                  type="radio"
                  name="expert-type"
                  value="development"
                  checked={expertType === 'development'}
                  onChange={onTypeChange('development')}
                />
              </TypeFilter>
              <TypeFilter active={expertType === 'aws'}>
                <img
                  src={ExpertAws}
                  css={`
                    margin-left: 30px;
                  `}
                />
                <div>
                  <Trans>AWS Expert</Trans>
                </div>
                <input
                  type="radio"
                  name="expert-type"
                  value="aws"
                  checked={expertType === 'aws'}
                  onChange={onTypeChange('aws')}
                />
              </TypeFilter>
            </TypeFilters>
            <CountryFilter>
              <img src={Location} />
              <Trans>
                I believe in borders and walls, I''d like to select where they're located:
              </Trans>
              <select
                name="country"
                id="country-filter"
                onChange={e => onCountryChange(e.target.value)}
                value={expertCountry}>
                <>
                  <option value="" key="earth">
                    Earth
                  </option>
                  {Countries.map(country => (
                    <option value={country.name} key={country.name}>
                      {country.name}
                    </option>
                  ))}
                </>
              </select>
            </CountryFilter>
          </ExpertFilterContainer>
        </ExpertFilterSection>
        <Experts>
          <ExpertContainer>
            <div>
              {isLoading && <Loader />}
              {!isLoading && expertList && expertList.length === 0 && (
                <ExpertListEmpty>
                  <Trans>There are no experts in this category for now</Trans>
                </ExpertListEmpty>
              )}
              {!isLoading &&
                expertList &&
                expertList.map(expert => (
                  <Expert key={expert.userId}>
                    <ExpertLogo>
                      <img
                        src={getExpertLogoUrl(expert.userId, expert.logo)}
                        alt={`Email Marketing Expert \u00BB${expert.company}\u00BB`}
                      />
                    </ExpertLogo>
                    <ExpertSummary>
                      <h4>
                        <span>{expert.company}</span>
                        <ExpertLoc>- {expert.country}</ExpertLoc>
                      </h4>
                      <p>{expert.description}</p>
                      <AnchorLink
                        href={expert.websiteUrl}
                        target="_blank"
                        external
                        title={`Email Marketing Expert \u00BB${expert.company}\u00BB`}>
                        {expert.websiteUrl}
                      </AnchorLink>
                    </ExpertSummary>
                    <Button
                      href={`mailto:${expert.email}`}
                      title={`Contact \u00BB${expert.company}\u00BB Email Marketing Expert`}>
                      <Trans>Contact</Trans>
                    </Button>
                  </Expert>
                ))}
            </div>
            {isMore && (
              <ShowMore>
                <Trans>Show more</Trans>
              </ShowMore>
            )}
          </ExpertContainer>
          <CurvedBorderBottom reversed />
        </Experts>
        <Stars>
          <BecomeExpertSection>
            <IndexSection>
              <IndexSectionContainer>
                <IndexSectionImage
                  src={MoonMailExpert}
                  alt={t('Inbound marketing experts')}
                  title={t('Inbound marketing experts')}
                  width="296"
                  height="201"
                />
                <IndexSectionSub>
                  <Trans>Interested in becoming a MoonMail Expert?</Trans>
                </IndexSectionSub>
                <h1>
                  <Trans>Skyrocket your Business with MoonMail</Trans>
                </h1>
                <IndexSectionSummary>
                  <Trans>
                    If you got marketing, design or development skills just join us! Apply to become
                    a MoonMail certified expert and get your skills advertised to thousands of
                    potential users, looking to hire an expert like you. This is a unique
                    opportunity to showcase your work and expand your business.
                  </Trans>
                </IndexSectionSummary>
                <p>
                  <Button href={links.mailto} title={t('Become an Email marketing expert')}>
                    {t('Contact us')}
                  </Button>
                </p>
              </IndexSectionContainer>
            </IndexSection>
          </BecomeExpertSection>
        </Stars>
      </div>
    </>
  );
};

export default MainPage;
